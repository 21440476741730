import styled from 'styled-components';
import colors from 'material-colors';


const Icon = styled.i`
  font-size: 24px;
  color: ${colors.grey[500]};
  &.icon-download {
    color: ${colors.red[300]}
  }
  &.icon-map, &.icon-map2 {
    color: ${colors.blue[300]}
  }
`;

export default Icon;
