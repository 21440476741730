import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import ReactGA from 'react-ga4';
import { stringify } from 'csv-string';
import display from '../utils/display';
import Icon from './Icon';

function Download(props) {
  const { fields, data, name } = props;
  const rows = [fields.map(field => field.name || '')];
  data.forEach((row) => {
    rows.push(fields.map(field => display(row[field.id])));
  });
  const dataString = stringify(rows);
  const blob = new Blob([dataString], { type: 'text/plain;charset=utf-8' });
  const filename = `${name}.csv`;
  const download = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    ReactGA.event({
      category: 'table',
      action: 'download',
      label: name,
    });
    FileSaver.saveAs(blob, filename);
  };
  return <a href={filename} onClick={download} download title="Download as CSV"><Icon className="icon-download" /></a>;
}

Download.propTypes = {
  fields: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default Download;
