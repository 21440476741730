/**
 *
 * NumericInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormControl } from 'react-bootstrap';

const Wrapper = styled.div`
  input.form-control {
    text-align: right;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -webkit-appearance: none;
    }
  }
`;

/* eslint-disable react/prefer-stateless-function */
class NumericInput extends React.PureComponent {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = { value };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value }); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleChange(ev) {
    const { onChange } = this.props;
    const { value } = ev.target;
    this.setState({ value });
    onChange(Number(value));
  }

  render() {
    const { value } = this.state;
    const props = {
      ...this.props,
      type: 'number',
      value,
      onChange: this.handleChange,
    };
    return (
      <Wrapper>
        <FormControl {...props} />
      </Wrapper>
    );
  }
}

NumericInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumericInput;
