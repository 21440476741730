import findKey from 'lodash/findKey';
import isInvalid from '../isInvalid';
import { getTypeOrValue } from '../../config/fit';
import getFieldData from './getFieldData';
import fitDefinition from '../../config/fit.json';

const FIELD_DESCRIPTION = fitDefinition.types.mesg_num.values.field_description;
const fieldDescriptionDef = fitDefinition.messages.field_description;
const NATIVE_MESG_NUM = findKey(fieldDescriptionDef, { name: 'native_mesg_num' });
const NATIVE_FIELD_NUM = findKey(fieldDescriptionDef, { name: 'native_field_num' });

function readData(file, localMessageType) {
  const result = localMessageType.fieldDefinitions.reduce((acc, def) => {
    const binValue = file.read(def.baseTypeLabel, def.size, !localMessageType.architecture);
    const fieldData = getFieldData(def);
    const key = fieldData.id;
    if (isInvalid(def.baseTypeLabel, binValue)) {
      return acc;
    }
    if (binValue === null) {
      return acc;
    }
    let value = binValue;
    if (def.fieldDefinition === undefined) {
      return { ...acc, [key]: { value } };
    }
    if (typeof binValue === 'number') {
      if (def.fieldDefinition.scale) {
        value /= def.fieldDefinition.scale;
      }
      if (def.fieldDefinition.offset) {
        value -= def.fieldDefinition.offset;
      }
    }
    if (def.fieldDefinition.type !== def.baseTypeLabel) {
      value = getTypeOrValue(def.fieldDefinition.type, value);
    }
    return {
      ...acc,
      [key]: { value, type: def.fieldDefinition.type },
    };
  }, {});
  if (localMessageType.globalMessageNumber === FIELD_DESCRIPTION && result[NATIVE_MESG_NUM]) {
    const nativeMessageType = fitDefinition.messages[result[NATIVE_MESG_NUM].value];
    if (result[NATIVE_FIELD_NUM] === undefined) {
      // eslint-disable-next-line no-console
      console.log('Expected attribute', NATIVE_FIELD_NUM, result, result[NATIVE_MESG_NUM].value);
    } else {
      const nativeFieldNum = result[NATIVE_FIELD_NUM].value;
      const nativeFieldType = nativeMessageType[nativeFieldNum];
      if (nativeFieldType !== undefined) {
        result[NATIVE_FIELD_NUM].value = nativeFieldType.name;
      }
    }
  }
  if (localMessageType.nDeveloperFields) {
    const developerResult = localMessageType.developerFieldDefinitions.reduce((acc, def) => {
      const value = file.read(def.baseTypeLabel, def.size, !localMessageType.architecture);
      const fieldData = getFieldData(def);
      const key = fieldData.id;
      if (def.baseTypeLabel !== 'string' && isNaN(value)) { // eslint-disable-line no-restricted-globals
        return acc;
      }
      return {
        ...acc,
        [key]: { value, type: def.baseTypeLabel },
      };
    }, {});
    Object.assign(result, developerResult);
  }
  return result;
}

export default readData;
