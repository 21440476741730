
function getArrayBuffer(buffer) {
  if (buffer instanceof ArrayBuffer) {
    return buffer;
  }
  const ab = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buffer.length; ++i) { // eslint-disable-line no-plusplus
    view[i] = buffer[i];
  }
  return ab;
}

function BinaryFile(content, littleEndian) {
  this.buffer = getArrayBuffer(content);
  this.position = 0;
  this.littleEndian = littleEndian;
}

BinaryFile.prototype.size = function () {
  return this.buffer.byteLength;
};

BinaryFile.prototype.tell = function () {
  return this.position;
};

BinaryFile.prototype.seek = function (pos) {
  this.position = pos;
};

BinaryFile.prototype.advance = function (size) {
  this.position += size;
};

BinaryFile.prototype.read = function (type, size, littleEndian = this.littleEndian) {
  switch (type) {
    case 'string': {
      return this.readString(size);
    }
    case 'byte': {
      return this.readBytes(size);
    }
    default: {
      return this.readNumber(type, size, littleEndian);
    }
  }
};

BinaryFile.prototype.readNumber = function (type, size, littleEndian) {
  const view = new DataView(this.buffer, this.position, size);
  this.position += size;
  switch (type) {
    case 'enum':
    case 'uint8':
    case 'uint8z':
      return view.getUint8(0);
    case 'sint8':
      return view.getInt8(0);
    case 'uint16':
    case 'uint16z':
      return view.getUint16(0, littleEndian);
    case 'sint16':
      return view.getInt16(0, littleEndian);
    case 'uint32':
    case 'uint32z':
      return view.getUint32(0, littleEndian);
    case 'sint32':
      return view.getInt32(0, littleEndian);
    case 'float32':
      return view.getFloat32(0, littleEndian).toPrecision(7);
    case 'float64':
      return view.getFloat64(0, littleEndian).toPrecision(16);
    default:
      throw new Error(`unknown type ${type}`);
  }
};

BinaryFile.prototype.readBytes = function (size) {
  const nextPosition = this.position + size;
  const result = new Uint8Array(this.buffer.slice(this.position, nextPosition));
  this.position = nextPosition;
  return result;
};

BinaryFile.prototype.readString = function (size) {
  const bytes = Array.from(this.readBytes(size));
  const i0 = bytes.findIndex(byte => byte === 0);
  if (i0 !== -1) {
    bytes.splice(i0);
  }
  return String.fromCharCode.apply(null, bytes);
};

BinaryFile.prototype.readUInt8 = function () {
  return this.read('uint8', 1);
};

BinaryFile.prototype.readUInt16 = function (littleEndian) {
  return this.read('uint16', 2, littleEndian);
};

BinaryFile.prototype.readUInt32 = function (littleEndian) {
  return this.read('uint32', 4, littleEndian);
};

BinaryFile.prototype.preview = function (count = 32, line = 16) {
  let offset = 0;
  const result = [];
  while (offset < count) {
    const start = this.position + offset;
    const bytes = new Uint8Array(this.buffer.slice(start, start + line));
    const str = Array.from(bytes).map(byte => byte.toString().padStart(3, ' '));
    str.splice(12, 0, '');
    str.splice(8, 0, '|');
    str.splice(4, 0, '');
    offset += line;
    const startStr = start.toString().padStart(6, ' ');
    result.push(`${startStr}: ${str.join(' ')}`);
  }
  return result.join('\n');
};

export default BinaryFile;
