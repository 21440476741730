/**
 *
 * Switch
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Switch from 'react-switch';
import colors from 'material-colors';

const Wrapper = styled.label`
  display: ${p => (p.inline ? 'inline-block' : 'flex')};
  margin-bottom: 0;
  align-items: center;
  justify-content: space-between;
`;

function MySwitch(props) {
  const {
    children, value, size, ...rest
  } = props;
  const height = ['small', 'sm'].includes(size) ? 20 : 28;
  const width = 2 * height;
  return (
    <Wrapper htmlFor={rest.id} inline={!children}>
      {children}
      <Switch
        checked={value}
        {...rest}
        height={height}
        width={width}
        onColor={colors.green[500]}
      />
    </Wrapper>
  );
}

MySwitch.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  value: PropTypes.bool.isRequired,
  size: PropTypes.string,
};

MySwitch.defaultProps = {
  children: null,
  size: undefined,
};

export default MySwitch;
