/*
 * Created from FIT SDK 21.84.00 import in https://docs.google.com/spreadsheets/d/1FM_mfJUU4lqffnY-1kOkwrEgegnmpNJRj-ObglYiBlg/edit?usp=sharing
 * Result at https://script.google.com/macros/s/AKfycbxX6vYLVxHMdDRCSdBRrr_nuV561fO87pSuS3YuhDT5s9IYrpA/exec
*/

import fitDefinition from './fit.json';

export function flip(obj) {
  const keys = Object.keys(obj);
  return keys.reduce((acc, key) => {
    const val = obj[key];
    acc[val] = key;
    return acc;
  }, {});
}

export function getType(key, value) {
  const data = fitDefinition.types[key];
  if (!data) {
    return undefined;
  }
  if (data.labels === undefined) {
    data.labels = flip(data.values);
  }
  return data.labels[value];
}

export function getTypeOrValue(key, value) {
  const result = getType(key, value);
  return result === undefined ? value : result;
}
