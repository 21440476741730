import ReactGA from 'react-ga4';

const params = ['category', 'variable', 'label'];
const store = {};

function makeKey(options) {
  return params.reduce((acc, key) => [...acc, options[key]], '').join('|');
}

function start(options) {
  const key = makeKey(options);
  store[key] = Date.now();
}

function stop(options) {
  const key = makeKey(options);
  const tStart = store[key];
  if (!tStart) {
    console.error('Unrecognized timing', options); // eslint-disable-line no-console
    return;
  }
  const value = Date.now() - tStart;
  ReactGA.send({
    hitType: 'timing',
    timingCategory: options.category,
    timingVar: options.variable,
    timingLabel: options.label,
    timingValue: value,
  });
  delete store[key];
}

export default { start, stop };
