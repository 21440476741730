
const INVALID_VALUES = {
  enum: 0xFF,
  sint8: 0x7F,
  uint8: 0xFF,
  sint16: 0x7FFF,
  uint16: 0xFFFF,
  sint32: 0x7FFFFFFF,
  uint32: 0xFFFFFFFF,
  float32: 0xFFFFFFFF,
  float64: 0xFFFFFFFFFFFFFFFF,
  uint8z: 0x00,
  uint16z: 0x0000,
  uint32z: 0x00000000,
  byte: 0xFF,
};

function invalidByte(value) {
  return value === INVALID_VALUES.byte;
}

function isInvalid(type, value) {
  switch (type) {
    case 'string':
      return value.length === 0;
    case 'byte':
      return value.every(invalidByte);
    default:
      return value === INVALID_VALUES[type];
  }
}

export default isInvalid;
