import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import Switch from './Switch';

const Wrapper = styled(Modal)`
  padding: 10px;
  label + label {
    margin-top: 15px;
  }
`;

const options = [
  { key: 'unusedFields', label: 'Hide unused fields' },
  { key: 'unnamedFields', label: 'Hide fields without name (\'field #\')' },
  { key: 'unnamedMessages', label: 'Hide messages without name (\'mesg_num #\')' },
  { key: 'encodedValues', label: 'Replace encoded values (timestamp, position)' },
];

export const initialFilters = options.reduce((acc, option) => ({ ...acc, [option.key]: true }), {});

class FilterModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(key) {
    const { onChange } = this.props;
    return (val) => {
      ReactGA.event({
        category: 'settings',
        action: key,
        label: val ? 'on' : 'off',
      });
      onChange({ [key]: val });
    };
  }

  render() {
    const { value, onChange, ...rest } = this.props;
    return (
      <Wrapper {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>
            Filter fields and messages
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {options.map(option => (
            <Switch
              key={option.key}
              id={option.key}
              value={value[option.key]}
              onChange={this.handleChange(option.key)}
            >
              {option.label}
            </Switch>
          ))}
        </Modal.Body>
      </Wrapper>
    );
  }
}

FilterModal.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterModal;
