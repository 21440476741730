import React from 'react';

function AboutFit() {
  return (
    <ul>
      <li>A FIT file consists of <em>messages</em>.</li>
      <li>Each message has a <em>definition record</em> and a number of <em>data records</em>.</li>
      <li>This viewer has applied the definition to the corresponding data records to create meaningful tables.</li>
      <li>Click on the message headers below to view the table.</li>
      <li>See the <a href="https://www.thisisant.com/resources/fit">FIT SDK</a> for more details about the FIT file format.</li>
    </ul>
  );
}

export default AboutFit;
