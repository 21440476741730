const FIELDMAP = {
  position_lat: true,
  position_long: true,
  timestamp: false,
  altitude: false,
};

export const GEO_FIELDS = Object.keys(FIELDMAP);

export const GEO_REQUIRED = GEO_FIELDS.filter(fld => FIELDMAP[fld]);

export const GEO_TYPES = ['record', 'course_point', 'segment_point'];
