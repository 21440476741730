import hasData from './hasData';
import { int2iso } from '../datetime';
import { GEO_FIELDS, GEO_REQUIRED, GEO_TYPES } from './constants';

const MUL = 180 / (2 ** 31);

/**
 * Read message and return geographic points
 * @param {*} msg
 */
function readGeoMessage(msg) {
  if (!GEO_TYPES.includes(msg.definition.messageType) || msg.data.length === 0) {
    return [];
  }
  const idx = {};
  const hasGeo = msg.definition.fieldDefinitions.some((def) => {
    const field = def.fieldDefinition && GEO_FIELDS.includes(def.fieldDefinition.name);
    if (!field) {
      return false;
    }
    idx[def.fieldDefinition.name] = def.fieldDefinitionNumber;
    return GEO_REQUIRED.every(fld => idx[fld] !== undefined);
  });
  if (!hasGeo) {
    return [];
  }
  return msg.data.filter(hasData(idx)).map(row => ({
    lat: row[idx.position_lat] * MUL,
    lon: row[idx.position_long] * MUL,
    time: idx.timestamp && int2iso(row[idx.timestamp]),
    ele: idx.altitude && row[idx.altitude],
  }));
}

export default readGeoMessage;
