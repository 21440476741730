import builder from 'xmlbuilder';

function makeGpx(points) {
  const gpx = {
    gpx: {
      '@version': 1.1,
      '@creator': "FIT File Viewer, https://www.fitfileviewer.com",
      '@xmlns:xsi': "http://www.w3.org/2001/XMLSchema-instance",
      '@xsi:schemaLocation': "http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd",
      '@xmlns': "http://www.topografix.com/GPX/1/1",
      trk: {
        name: 'GPX from FIT',
        trkseg: {
          trkpt: points.map(pt => ({
            '@lat': pt.lat,
            '@lon': pt.lon,
            time: pt.time,
            ele: pt.ele,
          })),
        },
      },
    },
  };
  return builder.create(gpx).end({ pretty: true });
}

export default makeGpx;
