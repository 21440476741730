

const dummyStorage = {
  setItem: () => console.warn('No storage available'), // eslint-disable-line no-console
  getItem: () => undefined,
};
const storage = storageAvailable('localStorage') ? window.localStorage : dummyStorage;

function storageAvailable(type) {
  try {
    const testStorage = window[type];
    const x = '__storage_test__';
    testStorage.setItem(x, x);
    testStorage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}

export default storage;
