
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 20px;
  text-align: center;
`;

function Contact() {
  const str = 'bWFpbHRvOmhhcnJ5QGZpdGZpbGV2aWV3ZXIuY29t';
  return (
    <Wrapper>
      If the file is not decoded properly,
      please <b><a href={window.atob(str)}>contact me</a></b> and include the file in the message.
    </Wrapper>
  );
}

export default Contact;
