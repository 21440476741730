/**
 * Toggle property in state
 * Usage in constructor this.someToggle = toggleState('property').bind(this)
 * @param prop
 */

function toggleState(prop) {
  return function () {
    this.setState(prev => ({ [prop]: !prev[prop] }));
  };
}

export default toggleState;
