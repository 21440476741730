import React from 'react';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import ReactGA from 'react-ga4';
import makeGpx from '../utils/gpx/makeGpx';
import Icon from './Icon';

function Export(props) {
  const { points, name } = props;
  const filename = `${name}.gpx`;
  const download = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const gpxString = makeGpx(points);
    const blob = new Blob([gpxString], { type: 'application/gpx+xml;charset=utf-8' });
    ReactGA.event({
      category: 'file',
      action: 'export',
      label: 'gpx',
    });
    FileSaver.saveAs(blob, filename);
  };
  return <a href={filename} onClick={download} download title="Export GPX"><Icon className="icon-map" /></a>;
}

Export.propTypes = {
  points: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default Export;
