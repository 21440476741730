import React from 'react';
import styled from 'styled-components';
import throttle from 'lodash/throttle';
import ReactGA from 'react-ga4';
import { ListGroup } from 'react-bootstrap';
import FileUploader from './components/FileUploader';
import { readFitFile } from './utils/FitReader';
import ParseWorker from './utils/FitReader/parse.worker';
import DataView from './components/DataView';
import Flex from './components/Flex';
import Footer from './components/Footer';
import FilterSettings, { getFilterSettings, setFilterSettings } from './components/FilterSettings';
import logo from './fit2.png';
import timing from './utils/timing';
import AboutFit from './components/AboutFit';

import './style/bootstrap/css/bootstrap.css';
import './style/icomoon/style.css';
import './style/global.css';

import Status, {
  STATUS_IDLE, STATUS_PROCESSING, STATUS_LOADING, STATUS_ERROR,
} from './components/Status';
import Export from './components/Export';
import Contact from './components/Contact';

ReactGA.initialize('G-PDCM038GEV');
const Wrapper = styled.div`
  header {
    display: flex;
    align-items: center;
    h1 {
      margin-top: 10px;
    }
    img {
      width: 32px;
      height: 40px;
      margin-right: 10px;
    }
  }
  h2 + a {
    margin-left: 20px;
  }
`;

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      status: STATUS_IDLE,
      progress: undefined,
      errorMessage: '',
      filters: getFilterSettings(),
    };
    this.updateProgress = throttle(this.updateProgress, 500);
  }

  handleError = (error) => {
    this.setState({
      status: STATUS_ERROR,
      errorMessage: error.message,
    });
  }

  handleUpload = () => {
    timing.start({ category: 'File', variable: 'Load' });
    this.setState({ status: STATUS_LOADING });
  }

  updateProgress = (progress) => {
    this.setState({ progress });
  }

  handleProgress = (progress) => {
    this.updateProgress(progress);
  }

  handleChange = ({ filename, content }) => {
    try {
      timing.stop({ category: 'File', variable: 'Load' });
      timing.start({ category: 'File', variable: 'Process' });
      this.setState({ filename, status: STATUS_PROCESSING, progress: 0 });
      if (window.Worker) {
        const worker = new ParseWorker();
        worker.postMessage(content);
        worker.addEventListener('message', (event) => {
          const { data } = event;
          switch (data.status) {
            case 'error':
              this.handleError(new Error(data.error));
              break;
            case 'progress':
              this.updateProgress(data.value);
              break;
            case 'success':
              timing.stop({ category: 'File', variable: 'Process' });
              this.setState({ ...data.result, status: STATUS_IDLE });
              break;
            default:
              throw new Error('Unknown status from worker');
          }
        });
      } else {
        const result = readFitFile(content, this.handleProgress);
        timing.stop({ category: 'File', variable: 'Process' });
        this.setState({ ...result, status: STATUS_IDLE });
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  changeFilters = (changes) => {
    this.setState((prev) => {
      const filters = { ...prev.filters, ...changes };
      setFilterSettings(filters);
      return { filters };
    });
  }

  render() {
    const {
      data, filename, status, filters, points,
    } = this.state;
    const basename = filename && filename.replace(/\.\w+$/, '');
    return (
      <Wrapper>
        <header>
          <img alt="logo" src={logo} />
          <h1>FIT File Viewer</h1>
        </header>
        <FilterSettings value={filters} onChange={this.changeFilters} />
        <AboutFit />
        <Flex spaced center>
          {filename && (
            <Flex align="baseline">
              <h2>{filename}</h2>
              {points && points.length > 0 && <Export points={points} name={basename} />}
            </Flex>
          )}
          <FileUploader accept=".fit,.zip" onUpload={this.handleUpload} onChange={this.handleChange} onError={this.handleError} />
        </Flex>
        <Status {...this.state} />
        {status === STATUS_IDLE && data && (
          <ListGroup>
            {data.map(msg => <DataView name={basename} key={msg.id} filters={filters} message={msg} />)}
          </ListGroup>
        )}
        {Boolean(filename) && <Contact />}
        <Footer />
      </Wrapper>
    );
  }
}

export default App;
