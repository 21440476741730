
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 20px;
  border-top: 1px solid #eeeeee;
  color: #777777;
  text-align: center;
  font-size: smaller;
  a {
    font-style: italic;
    margin-left: 0.5rem;
  }
  img {
    height: 10px;
  }
`;

function Footer() {
  return (
    <Wrapper>
    Developed by Harry Oosterveen,
      <a href="https://www.harryonline.net/">
        HarryOnline <img alt="HarryOnline logo" src="https://s3-eu-west-1.amazonaws.com/logos.harryonline/ho_logo_24x26.png" />
      </a>
    </Wrapper>
  );
}

export default Footer;
