import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import colors from 'material-colors';
import { ListGroupItem, Table } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import display from '../utils/display';
import getFieldData from '../utils/FitReader/getFieldData';
import Flex from './Flex';
import Download from './Download';
import Pagination from './Pagination';
import { int2date } from '../utils/datetime';
import sc2deg from '../utils/gpx/sc2deg';
import Icon from './Icon';
import Export from './Export';

const Invalid = styled.span`
  opacity: 0.5;
`;

const small = css`
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.9;
`;

const Header = styled(ListGroupItem)`
  &.list-group-item {
    background-color: ${colors.green[50]};
    &:hover, &:focus {
      background-color: ${colors.green[100]};
    }
    margin-top: 20px;
    &:first-child {
      margin-top: 10px;
    }
    h3 {
      display: inline-block;
      font-size: 1.8rem;
      margin: 0;
    }
    .data-count {
      margin: 0 0.5rem;
    }
    a+ a, a + i {
      margin-left: 20px
    }
    .data-fields {
      ${small};
      margin-top: 5px;
    }
  }
`;

const DataTable = styled(Table)`
  &.table {
    font-size: 1.2rem;
    background-color: #fff;
    margin-bottom: 0;
  }
`;

const Units = styled.span`
  white-space: nowrap;
  margin-left: 0.2em;
  ${small};
`;

class DataView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { expanded: false, limit: 10, offset: 0 };
    this.setState = this.setState.bind(this);
  }

  getFields() {
    const { message: { definition } } = this.props;
    const fields = definition.developerFieldDefinitions ? [
      ...definition.fieldDefinitions,
      ...definition.developerFieldDefinitions,
    ] : definition.fieldDefinitions;
    return fields
      .filter(this.filterUnnamedFields)
      .map(getFieldData)
      .filter(this.filterUnusedFields);
  }

  filterUnnamedFields = (field) => {
    const { filters } = this.props;
    return !filters.unnamedFields || field.fieldDefinition || field.fieldDescription;
  }

  filterUnusedFields =(field) => {
    const { message: { data }, filters } = this.props;
    return !filters.unusedFields || data.some(row => row[field.id] !== undefined);
  }

  toggleExpand = () => {
    const { message: { definition } } = this.props;
    this.setState((prev) => {
      const { expanded } = prev;
      ReactGA.event({
        category: 'table',
        action: 'expand',
        label: definition.messageType || `mesg_num ${definition.globalMessageNumber}`,
        value: Number(!expanded),
      });
      return { expanded: !expanded };
    });
  }

  fieldValue(row, field) {
    const { filters } = this.props;
    const value = row[field.id];
    if (!filters.encodedValues) {
      return value;
    }
    if (field.type === 'date_time') {
      return int2date(value);
    }
    if (field.units === 'semicircles') {
      return sc2deg(value);
    }
    return display(value, '-');
  }

  renderLabel() {
    const { message: { definition } } = this.props;
    return definition.messageType || <Invalid>{`mesg_num ${definition.globalMessageNumber}`}</Invalid>;
  }

  renderDataRow() {
    return (row, idx) => (
      <tr key={idx}>
        {this.fields.map(field => <td key={field.id}>{display(this.fieldValue(row, field), '-')}</td>)}
      </tr>
    );
  }

  renderUnits(field) {
    const { filters } = this.props;
    let { units } = field;
    if (filters.encodedValues && field.type === 'date_time') {
      units = null;
    }
    if (filters.encodedValues && field.units === 'semicircles') {
      units = '°';
    }
    return units && <Units>({units})</Units>;
  }

  render() {
    const { message, name, filters } = this.props;
    const {
      id, data, definition, points,
    } = message;
    if (!definition.messageType && filters.unnamedMessages) {
      return null;
    }
    const { expanded, offset, limit } = this.state;
    this.fields = this.getFields();
    const pagedData = data.slice(offset, offset + limit);
    const basename = `${name}_${id}`;
    return (
      <React.Fragment>
        <Header onClick={this.toggleExpand}>
          <Flex center spaced>
            <div>
              <h3>{this.renderLabel()}</h3>
              <span className="data-count">{data.length} row(s)</span>
            </div>
            <div>
              { points && <Export name={basename} points={points} /> }
              <Download name={basename} data={data} fields={this.fields} />
              <Icon className={expanded ? 'icon-circle-up' : 'icon-circle-down'} />
            </div>
          </Flex>
          {!expanded && (
            <div className="data-fields">
              {this.fields.filter(field => field.name).map(field => field.name).join(', ')}
            </div>
          )
          }
        </Header>
        {expanded && (
          <React.Fragment>
            <DataTable responsive bordered condensed>
              <thead>
                <tr>
                  {this.fields.map(field => (
                    <th key={field.id}>
                      {field.name || `field ${field.id}`}
                      {this.renderUnits(field)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {pagedData.map(this.renderDataRow())}
              </tbody>
            </DataTable>
            { data.length > 10 && <Pagination offset={offset} limit={limit} max={data.length} onChange={this.setState} /> }
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

DataView.propTypes = {
  message: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  filters: PropTypes.object.isRequired,
};

export default DataView;
