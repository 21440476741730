/**
 *
 * Flex
 * create flex div, use bool attributes column, center, spaced, and/or specify justify and align attributes
 *
 */

// import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  justify-content: ${p => (p.spaced ? 'space-between' : p.justify)};
  align-items: ${p => (p.center ? 'center' : p.align)};
  & > .grow {
    flex-grow: 1;
  } 
`;

Flex.propTypes = {
  column: PropTypes.bool,
  spaced: PropTypes.bool,
  center: PropTypes.bool,
  justify: PropTypes.string,
  align: PropTypes.string,
};

Flex.defaultProps = {
  justify: 'normal',
  align: 'normal',
};

export default Flex;
