import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button, ButtonGroup, Form, FormGroup, ControlLabel, FormControl,
} from 'react-bootstrap';
import NumericInput from './NumericInput';

const Wrapper = styled(Form)`
    text-align: right;
    margin-top: 10px;
    font-size: smaller;
    .form-group {
      margin-left: 5px;
      margin-right: 5px;
    }
    .btn-group {
      margin-left: 5px;
      font-weight: bold;
    }
    .form-control {
      height: 24px;
      font-size: 12px;
    }
    input, button, select {
      padding: 1px 5px
    }
  `;

class Pagination extends React.PureComponent {
  constructor(props) {
    super(props);
    const { offset } = props;
    this.state = { value: offset + 1 };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changeOffset(offset) {
    const { onChange } = this.props;
    onChange({ offset });
    this.setState({ value: offset + 1 });
  }

  handleChange(key) {
    const {
      onChange, offset, max, limit,
    } = this.props;
    return (ev) => {
      const value = Number(ev.target ? ev.target.value : ev);
      switch (key) {
        case 'value':
          this.setState({ value });
          break;
        case 'offset':
          onChange({ [key]: value - 1 });
          break;
        case 'prev':
          this.changeOffset(Math.max(0, offset - limit));
          break;
        case 'next':
          this.changeOffset(Math.min(max, offset + limit));
          break;
        default:
          onChange({ [key]: value });
      }
    };
  }

  handleSubmit(ev) {
    const { onChange } = this.props;
    const { value } = this.state;
    ev.preventDefault();
    onChange({ offset: value - 1 });
  }

  render() {
    const { offset, limit, max } = this.props;
    const { value } = this.state;
    const limitOptions = [10, 25, 50, 100];
    return (
      <Wrapper inline onSubmit={this.handleSubmit}>
        Show rows:
        <FormGroup controlId="limit">
          <ControlLabel className="sr-only">Limit</ControlLabel>
          <FormControl componentClass="select" value={limit} onChange={this.handleChange('limit')}>
            {limitOptions.map(option => <option key={option} value={option}>{option}</option>)}
          </FormControl>
        </FormGroup>
        Go to:
        <FormGroup controlId="offset">
          <ControlLabel className="sr-only">Offset</ControlLabel>
          <FormControl componentClass={NumericInput} min={1} max={max} value={value} onChange={this.handleChange('value')} onBlur={this.handleChange('offset')} />
        </FormGroup>
        {offset + 1}–{Math.min(offset + limit, max)} of {max}
        <ButtonGroup>
          <Button disabled={offset <= 0} onClick={this.handleChange('prev')}>&lt;</Button>
          <Button disabled={offset + limit >= max} onClick={this.handleChange('next')}>&gt;</Button>
        </ButtonGroup>
      </Wrapper>
    );
  }
}

Pagination.propTypes = {
  offset: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Pagination;
