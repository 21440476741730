import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import JSZip from 'jszip';

/* eslint-disable jsx-a11y/label-has-associated-control */

const Wrapper = styled.div`
  [type="file"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }

  [type="file"]:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
  }
`;

class FileUploader extends React.PureComponent {
  static findFitFile(zip) {
    const fileKey = Object.keys(zip.files).find((key => /\.fit$/i.test(key)));
    return fileKey && zip.files[fileKey];
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  loadZip(file) {
    const { onChange, onError } = this.props;
    let filename = file.name.replace(/\.zip$/i, '.fit');
    JSZip.loadAsync(file)
      .then((zip) => {
        const zipFile = FileUploader.findFitFile(zip);
        if (!zipFile) {
          throw new Error(`FIT file '${filename}' not found in zip`);
        }
        filename = zipFile.name;
        return zipFile.async('arraybuffer');
      })
      .then(content => onChange({ filename, content }))
      .catch(onError);
  }

  loadFit(file) {
    const { onChange } = this.props;
    const fileReader = new FileReader(); // eslint-disable-line no-undef
    fileReader.onload = (event) => {
      onChange({ filename: file.name, content: event.target.result });
    };
    fileReader.readAsArrayBuffer(file);
  }

  handleChange(ev) {
    const file = ev.target.files[0];
    const { onUpload } = this.props;
    onUpload();
    if (/\.zip$/i.test(file.name)) {
      this.loadZip(file);
    } else {
      this.loadFit(file);
    }
  }

  render() {
    const { accept } = this.props;
    return (
      <Wrapper>
        <input type="file" id="file-input" value="" className="btn" accept={accept} onChange={this.handleChange} />
        <label htmlFor="file-input" className="btn btn-primary">Open FIT file</label>
      </Wrapper>
    );
  }
}

FileUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  accept: PropTypes.string,
};

FileUploader.defaultProps = {
  accept: '',
  onUpload: undefined,
};

export default FileUploader;
