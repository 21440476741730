
import BinaryFile from '../BinaryFile';
import { fromOffset } from '../datetime';
import toValues from './toValues';
import readFileHeader from './readFileHeader';
import readRecordHeader from './readRecordHeader';
import readDefinition from './readDefinition';
import readData from './readData';
import namedFields from './namedFields';
import readGeoMessage from '../gpx/readGeoMessage';

function FitReader(buffer) {
  this.file = new BinaryFile(buffer, true);
  this.header = null;
  this.maxPos = undefined;
  this.lastTimestamp = undefined;
  this.globalData = [];
  this.localMessageTypeList = {};
  this.developerDataFields = {
    developer_data_id: [],
    field_description: [],
  };
}

FitReader.prototype.parse = function (onProgress) {
  this.readHeader();
  const ignoreMaxPos = this.maxPos < this.file.size() / 2;
  this.idObject = {};
  while (this.file.tell() < (ignoreMaxPos ? this.file.size() : this.maxPos)) {
    try {
      this.readRecord();
      onProgress(Math.floor(100 * (this.file.tell() / this.maxPos)));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      break;
    }
  }
};

FitReader.prototype.readHeader = function () {
  this.header = readFileHeader(this.file);
  this.maxPos = this.header.headerSize + this.header.dataSize;
};

FitReader.prototype.getId = function (definition) {
  const name = definition.messageType || `mesg_num_${definition.globalMessageNumber}`;
  this.idObject[name] = (this.idObject[name] || 0) + 1;
  return `${name}_${this.idObject[name]}`;
};

FitReader.prototype.readRecord = function () {
  // const curPos = this.file.tell();
  const recordHeader = readRecordHeader(this.file);
  if (recordHeader.messageType === 'definition') {
    const data = readDefinition(this.file, recordHeader.developerData
      ? this.developerDataFields : null);
    data.globalIndex = this.globalData.length;
    this.localMessageTypeList[recordHeader.localMessageType] = data;
    this.globalData.push({ id: this.getId(data), definition: data, data: [] });
  } else {
    try {
      const localMessageType = this.localMessageTypeList[recordHeader.localMessageType];
      const data = readData(this.file, localMessageType);
      if (data.timestamp) {
        this.lastTimestamp = data.timestamp;
      }
      if (recordHeader.headerType === 'compressed timestamp') {
        data.timestamp = fromOffset(recordHeader.timeOffset, this.lastTimestamp.value);
      }
      const values = toValues(data);
      this.globalData[localMessageType.globalIndex].data.push(values);
      const msgType = localMessageType.messageType;
      if (this.developerDataFields[msgType]) {
        this.developerDataFields[msgType].push(namedFields(localMessageType.fieldDefinitions, values));
      }
    } catch (error) {
      console.error(error);
      postMessage({ status: 'error', error: error.message });
    }
  }
};

export default FitReader;

export function readFitFile(buffer, onProgress) {
  const reader = new FitReader(buffer);
  reader.parse(onProgress);
  const allPoints = [];
  const result = {
    data: reader.globalData.map((msg) => {
      const points = readGeoMessage(msg);
      if (points.length === 0) {
        return msg;
      }
      allPoints.push(points);
      return {
        ...msg,
        points,
      };
    }),
  };
  result.points = allPoints.flat().sort((a, b) => (Math.sign(a - b)));
  return result;
}
