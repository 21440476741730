import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import storage from '../utils/storage';
import FilterModal, { initialFilters } from './FilterModal';
import toggleState from '../utils/toggleState';

const FILTER_KEY = 'fitfileviewer_filters';

export function getFilterSettings() {
  const storedFilterString = storage.getItem(FILTER_KEY);
  return storedFilterString ? JSON.parse(storedFilterString) : initialFilters;
}

export function setFilterSettings(filters) {
  storage.setItem(FILTER_KEY, JSON.stringify(filters));
}

class FilterSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggleShow = toggleState('show').bind(this);
    this.state = { show: false };
  }

  render() {
    const { value, onChange } = this.props;
    const { show } = this.state;
    const count = Object.keys(value).reduce((sum, key) => (value[key] ? sum + 1 : sum), 0);
    return (
      <React.Fragment>
        <Button
          bsStyle="link"
          className="pull-right"
          onClick={this.toggleShow}
        >
          <i className="icon-cog" /> Filters ({count})
        </Button>
        <FilterModal show={show} value={value} onHide={this.toggleShow} onChange={onChange} />
      </React.Fragment>
    );
  }
}

FilterSettings.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterSettings;
