import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Alert, ProgressBar } from 'react-bootstrap';
import colors from 'material-colors';

export const STATUS_IDLE = 0;
export const STATUS_LOADING = 1;
export const STATUS_PROCESSING = 2;
export const STATUS_ERROR = 3;

const STATUSLABELS = ['', 'Loading', 'Processing', 'Error'];

const HELLIP = String.fromCharCode(8230);

const Wrapper = styled.div`
  padding: 10px 0;
  .progress-bar {
    background-color: ${colors.green[400]};
  }
`;

function Status(props) {
  const { status, progress, errorMessage } = props;
  const label = STATUSLABELS[status];
  if (status === STATUS_PROCESSING && progress !== undefined) {
    return <Wrapper><ProgressBar now={progress} /></Wrapper>;
  }
  if (status === STATUS_ERROR && errorMessage !== undefined) {
    return <Wrapper><Alert bsStyle="danger">{errorMessage}</Alert></Wrapper>;
  }
  if (!label) {
    return null;
  }
  return <span>{label}{HELLIP}</span>;
}

Status.propTypes = {
  status: PropTypes.number.isRequired,
  progress: PropTypes.number,
  errorMessage: PropTypes.string,
};

Status.defaultProps = {
  progress: undefined,
  errorMessage: '',
};


export default Status;
