// import { int2date } from './datetime';

function toValue(obj) {
  switch (obj.type) {
    case 'byte':
      return obj.value.toString();
    // case 'date_time':
    // case 'local_date_time':
    //   return { value: obj.value, string: int2date(obj.value) };
    default:
      return obj.value;
  }
}

export default function toValues(data) {
  return Object.keys(data).reduce((acc, key) => ({
    ...acc,
    [key]: toValue(data[key]),
  }), {});
}
